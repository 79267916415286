@import 'src/styles/typography';
@import 'src/styles/gaps';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.container {
	width: 100%;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 130px;

	@include sm {
		flex-direction: column;
		gap: $gap-m;
		height: 80vh;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: $gap-m;
	width: 350px;

	@include sm {
		align-items: center;
		width: 320px;
	}
}

.title {
	@include sm {
		text-align: center;
	}
}

.mainDescription {
	color: $text-description-grey;
	margin-top: $gap-s;

	@include sm {
		text-align: center;
		width: 320px;
	}

	@include text-m;
}

.secondaryDescription {
	color: $text-description-grey;
	@include sm {
		text-align: center;
		width: 320px;
	}

	@include text-xs;
}

.linkButton {
	display: flex;
	justify-content: center;
	padding: 12px;
	border-radius: 10px;
	background-color: $blue;
	color: white;
	width: 120px;
	margin-top: $gap-s;

	@include text-s;
	line-height: 18px;
}
